/** @format */

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { GetProperties } from "../../services/Api/Api";
import { useNavigate } from "react-router";
import { InputText } from "primereact/inputtext";
import { Box } from "@material-ui/core";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { Button } from "primereact/button";
import moment from "moment";

const Property = () => {
	const navigate = useNavigate();
	const [data, setData] = useState([]);
	const [userBackupData, setUserBackupData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [tableParams, setTableParams] = useState({
		pagination: {
			current: 1,
			pageSize: 20,
		},
		sortField: null,
		sortOrder: null,
	});

	const columns = [
		{
			title: "S.No.",
			dataIndex: "index",
			sorter: (a, b) => a.index - b.index,
		},
		{
			title: "Property Owner",
			dataIndex: "property_owner_name",
			key: "type",
		},
		{
			title: "Rental Property Address",
			dataIndex: "full_address",
			key: "address",
		},
		{
			title: "Subscription Start Time",
			dataIndex: "subscription_start_date",
			key: "subscription_start_date",
			render: (date) =>
				date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "---",
		},

		{
			title: "Subscription End Time",
			dataIndex: "subscription_end_date",
			key: "subscription_end_date",
			render: (date) =>
				date ? moment(date).format("YYYY-MM-DD HH:mm:ss") : "---",
		},
		{
			title: "",
			key: "redirect",
			render: (text, record) => (
				<IoArrowForwardCircleOutline
					style={{ fontSize: "20px", cursor: "pointer" }}
					className="redirect_button"
					onClick={() => navigate(`/viewPropertyDetails/${record.id}`)}
				/>
			),
		},
	];

	const getData = async (params = {}) => {
		try {
			setLoading(true);
			let result = await GetProperties(
				localStorage.getItem("adminToken"),
				params
			);
			// Adding index for serial number
			const newData = result.data.data.map((item, index) => ({
				...item,
				index: index + 1,
			}));
			setData(newData);
			setUserBackupData(newData);
		} catch (e) {
			console.error(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
			}
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		getData({
			page: tableParams.pagination.current,
			pageSize: tableParams.pagination.pageSize,
			sortField: tableParams.sortField,
			sortOrder: tableParams.sortOrder,
		});
	}, [tableParams]);

	const handleTableChange = (pagination, filters, sorter) => {
		setTableParams({
			pagination,
			filters,
			sortField: sorter.field,
			sortOrder: sorter.order,
		});
	};

	const onSearch = (searchField) => {
		const searchList = userBackupData.filter((item) =>
			[
				item.property_owner_name?.toLowerCase(),
				item.property_owner_email?.toLowerCase(),
			].some((value) => value?.includes(searchField.toLowerCase()))
		);
		setData(searchList);
	};

	// CSV Export Function
	const exportToCSV = () => {
		const csvHeaders = [
			"S.No.",
			"Property Owner",
			"Property Owner Email 1",
			"Property Owner Email 2",
			"Property Owner Mobile 1",
			"Property Owner Mobile 2",
			"Address",
			"Address Line 1",
			"Address Line 2",
			"Country",
			"State",
			"City",
			"Zip Code",
			"Uploaded By",
		];
		const csvRows = data.map((row) => [
			row.index,
			row.property_owner_name,
			row.property_owner_email_1,
			row.property_owner_email_2,
			row.property_owner_mobile_number_1,
			row.property_owner_mobile_number_2,
			`"${row.full_address}"`, 
			row.address_line_1,
			row.address_line_2,
			row.country,
			row.state,
			row.city,
			row.zip_code,
			row.properties_users?.user_profile?.name,
		]);

		const csvContent = [csvHeaders, ...csvRows]
			.map((e) => e.join(","))
			.join("\n");

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "properties.csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">PROPERTY MANAGEMENT</h3>
					<p className="page-sub-title">Manage properties</p>
				</div>
				<Box display="flex" alignItems="center">
					<Box>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								type="search"
								onChange={(e) => onSearch(e.target.value)}
								placeholder="Search..."
							/>
						</span>
					</Box>
					<Button
						icon="pi pi-cloud-download"
						severity="success"
						style={{ marginLeft: "10px", borderRadius: "5px", height: "47px" }}
						onClick={exportToCSV}
					/>
				</Box>
			</Box>
			<Table
				columns={columns}
				rowKey={(record) => record.id}
				dataSource={data}
				pagination={tableParams.pagination}
				loading={loading}
				onChange={handleTableChange}
			/>
		</Box>
	);
};

export default Property;
