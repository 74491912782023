/** @format */

import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { InputText } from "primereact/inputtext";
import { Box } from "@material-ui/core";
import { Button } from "primereact/button";
import moment from "moment";
import { GetTransactionHistory } from "../../services/Api/DashboardApi";
import { useLocation, useNavigate } from "react-router-dom";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

const Transactions = () => {
	const navigate = useNavigate();
	const [transactions, setTransactions] = useState([]);
	const [filteredTransactions, setFilteredTransactions] = useState([]);
	const [loading, setLoading] = useState(true);
	const [searchTerm, setSearchTerm] = useState(""); // State for search term

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const transactionPeriod = decodeURIComponent(queryParams.get("period"));
	const fromDate = queryParams.get("fromDate");
	const toDate = queryParams.get("toDate");

	const columns = [
		{
			title: "S.No.",
			render: (text, record, index) => index + 1,
			sorter: (a, b) => a.index - b.index,
		},
		{
			title: "Name",
			dataIndex: "user_name",
			width: "20%",
		},
		{
			title: "Amount Paid",
			dataIndex: "amount",
			width: "20%",
		},
		{
			title: "Receipt Url",
			dataIndex: "receipt_url",
			width: "20%",
			render: (url) => (
				<a href={url} target="_blank" rel="noopener noreferrer" title={url}>
					{url.length > 30 ? `${url.slice(0, 30)}...` : url}
				</a>
			),
		},
		{
			title: "Payment Mode",
			dataIndex: "payment_mode",
			width: "20%",
		},
		{
			title: "Payment Date",
			dataIndex: "created_at",
			width: "30%",
			render: (date) => moment(date).format("YYYY-MM-DD HH:mm:ss"),
		},
		{
			title: "Status",
			dataIndex: "payment_status",
			width: "20%",
		},
	];


	const fetchTransactions = async (period, fromDate = null, toDate = null) => {
		setLoading(true);
		try {
			const result = await GetTransactionHistory(period, fromDate, toDate);
			setTransactions(result.data.data?.data?.payments || []);
			setFilteredTransactions(result.data.data?.data?.payments || []); 
		} catch (e) {
			console.log(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const transactionPeriod = decodeURIComponent(queryParams.get("period"));
		const fromDate = queryParams.get("fromDate");
		const toDate = queryParams.get("toDate");

		if (transactionPeriod === "custom" && fromDate && toDate) {

			fetchTransactions(transactionPeriod, fromDate, toDate);
		} else if (transactionPeriod) {

			fetchTransactions(transactionPeriod);
		}
	}, [location.search]);

	
	const onSearch = (searchField) => {
		setSearchTerm(searchField);
		const filteredData = transactions.filter((item) => {
			const userName = item.user_name?.toLowerCase() || ""; 
			const searchTerm = searchField.toLowerCase();
			return userName.includes(searchTerm);
		});
		setFilteredTransactions(filteredData); 
	};

	// Export to PDF
	const exportToPDF = () => {
		const doc = new jsPDF();

		// Define table headers and rows
		const tableHeaders = [
			"S.No.",
			"Name",
			"Amount Paid",
			"Receipt URL",
			"Payment Mode",
			"Payment Date",
			"Status",
		];

		const tableRows = transactions.map((row, index) => {
			const truncatedUrl =
				row.receipt_url.length > 30
					? `${row.receipt_url.slice(0, 30)}...`
					: row.receipt_url;

			return [
				index + 1,
				row.user_name,
				row.amount,
				truncatedUrl, // Truncate URL for the PDF
				row.payment_mode,
				moment(row.created_at).format("YYYY-MM-DD HH:mm:ss"),
				row.payment_status,
			];
		});

		// Add the table to the PDF
		doc.autoTable({
			head: [tableHeaders],
			body: tableRows,
			startY: 20,
			theme: "grid",
			columnStyles: {
				3: { cellWidth: 40 }, // Adjust Receipt URL column width
			},
		});

		// Save the PDF with a name
		doc.save("transactions.pdf");
	};

	const navigateToUser = () => {
		navigate(`/`);
	};

	return (
		<Box>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				marginBottom="20px"
			>
				<div>
					<h3 className="page-title">Transactions MANAGEMENT</h3>
					<p className="page-sub-title">All Transactions List</p>
				</div>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					<div>
						<Button
							icon="pi pi-arrow-left"
							severity="secondary"
							onClick={navigateToUser}
							style={{
								borderRadius: "5px",
								height: "47px",
								marginRight: "20px",
							}}
						>
							<span style={{ marginLeft: "5px" }}>Return to Dashboard</span>
						</Button>
					</div>
					<div>
						<span className="p-input-icon-left">
							<i className="pi pi-search" />
							<InputText
								type="search"
								onChange={(e) => {
									onSearch(e.target.value); // Update filtered data on input change
								}}
								placeholder="Search..."
								value={searchTerm} // Bind the search term to the input field
							/>
						</span>
					</div>
					<div>
						<Button
							icon="pi pi-cloud-download"
							severity="info"
							style={{
								marginLeft: "10px",
								borderRadius: "5px",
								height: "47px",
							}}
							onClick={exportToPDF}
						/>
					</div>
				</Box>
			</Box>

			<Table
				columns={columns}
				dataSource={filteredTransactions}
				rowKey="transaction_id"
				pagination={false}
				loading={loading}
			/>
		</Box>
	);
};

export default Transactions;
