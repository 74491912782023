/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Column, Pie } from "@ant-design/plots";
import "./Dashboard.css";
import { Select, Spin, Table, DatePicker } from "antd";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import Flag from "react-world-flags";
import {
	GetContactUsFormCount,
	GetPropertyCountByCountry,
	GetPropertyRegisteredStatistics,
	GetTopPropertyOwner,
	GetTotalpropertyData,
	GetTotalRevenue,
	GetTransactionHistory,
	GetUserCount,
	GetUserRegisteredStatistics,
} from "../../services/Api/DashboardApi";
import moment from "moment";
const { Option } = Select;

const Dashboard = () => {
	const navigate = useNavigate();
	const [customerData, setCustomerData] = useState([]);
	const [contactUsData, setContactUsData] = useState([]);
	const [propertyData, setPropertyData] = useState([]);
	const [revenueData, setRevenueData] = useState([]);
	const [salesCount, setSalesCount] = useState([]);
	const [propertyOwner, setPropertyOwner] = useState([]);
	const [propertyCountByCountryData, setPropertyCountByCountryData] = useState(
		[]
	);
	const [period, setPeriod] = useState("today");
	const [propertyCount, setPropertyCount] = useState([]);
	const [propertyPeriod, setPropertyPeriod] = useState("today");
	const [loading, setLoading] = useState(true);

	const [transactionPeriod, setTransactionPeriod] = useState("today");
	const [transactions, setTransactions] = useState([]);
	const [fromDate, setFromDate] = useState(null);
	const [toDate, setToDate] = useState(null);

	const countryCodeMap = {
		Canada: "CA",
		"United States": "US",
	};

	//get customer count
	const getCustomerData = async () => {
		try {
			const result = await GetUserCount(localStorage.getItem("adminToken"));
			setCustomerData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getPropertyData = async () => {
		try {
			const result = await GetTotalpropertyData(
				localStorage.getItem("adminToken")
			);
			setPropertyData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getContactUsData = async () => {
		try {
			const result = await GetContactUsFormCount(
				localStorage.getItem("adminToken")
			);
			setContactUsData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getTotalRevnueGenerated = async () => {
		try {
			const result = await GetTotalRevenue(localStorage.getItem("adminToken"));
			setRevenueData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getPropertyOwnerData = async () => {
		try {
			setLoading(true);
			let result = await GetTopPropertyOwner(
				localStorage.getItem("adminToken")
			);

			setPropertyOwner(result.data.data);
		} catch (e) {
			console.log(e);
			if (e.response && e.response.status === 401) {
				navigate("/error401");
				console.log("You do not have access to this page as a sub-admin.");
			} else {
				console.log("Error loading data. Please try again later.");
			}
		} finally {
			setLoading(false);
		}
	};

	const getPropertyCountByCountryData = async () => {
		try {
			const result = await GetPropertyCountByCountry(
				localStorage.getItem("adminToken")
			);
			setPropertyCountByCountryData(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const handlePeriodChange = async (newPeriod) => {
		setPeriod(newPeriod); // Update the period state
		try {
			const result = await GetUserRegisteredStatistics(newPeriod); // Call the API
			setSalesCount(result.data.data || 0); // Update sales count with the result data
		} catch (e) {
			console.log(e);
		}
	};

	const handlePropertyPeriodChange = async (newPeriod) => {
		setPropertyPeriod(newPeriod); // Update the period state
		try {
			const result = await GetPropertyRegisteredStatistics(newPeriod); // Call the API
			setPropertyCount(result.data.data || 0); // Update sales count with the result data
		} catch (e) {
			console.log(e);
		}
	};

	const fetchTransactionData = async (
		period,
		fromDate = null,
		toDate = null
	) => {
		// setTransactionPeriod(newPeriod); // Update the period state
		try {
			console.log(period, "period");
			// Call the API based on the selected period (and custom date range if applicable)
			const result = await GetTransactionHistory(period, fromDate, toDate);
			const data = result.data.data.data || [];
			setTransactions(data); // Set the data into the state
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		handlePeriodChange(period);
		handlePropertyPeriodChange(propertyPeriod);
		fetchTransactionData(transactionPeriod); /// Fetch data for the default period when the component loads
	}, []);

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true); // Start loading
			await Promise.all([
				getCustomerData(),
				getContactUsData(),
				getPropertyData(),
				getTotalRevnueGenerated(),
				getPropertyOwnerData(),
				getPropertyCountByCountryData(),
			]);
			setLoading(false); // Stop loading once all data is fetched
		};
		fetchData();
	}, []);

	const config = {
		data: [{ type: period, sales: salesCount }],
		xField: "type",
		yField: "sales",
		color: "#4CAF50",
		columnWidthRatio: 0.2, // Adjust column width (0.6 is an example; adjust as needed)
		label: {
			position: "middle",
			style: {
				fill: "#FFFFFF",
				opacity: 1,
			},
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		yAxis: {
			min: 0, // Start the y-axis from 0
			max: 50, // End the y-axis at 50 or adjust based on your data
			tickInterval: 10, // Set interval to display ticks as 10, 20, 30, etc.
			label: {
				formatter: (v) => `${v}`, // Format y-axis labels as numbers
			},
		},
		meta: {
			type: {
				alias: "Period", // Label for the x-axis
			},
			sales: {
				alias: "Number of Registered Users", // Label for the y-axis
			},
		},
	};

	const propertyConfig = {
		data: [{ type: period, sales: propertyCount }],
		xField: "type",
		yField: "sales",
		color: "#4CAF50",
		columnWidthRatio: 0.2, // Adjust column width (0.6 is an example; adjust as needed)
		label: {
			position: "middle",
			style: {
				fill: "#FFFFFF",
				opacity: 1,
			},
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},
		yAxis: {
			min: 0, // Start the y-axis from 0
			max: 50, // End the y-axis at 50 or adjust based on your data
			tickInterval: 10, // Set interval to display ticks as 10, 20, 30, etc.
			label: {
				formatter: (v) => `${v}`, // Format y-axis labels as numbers
			},
		},
		meta: {
			type: {
				alias: "Period", // Label for the x-axis
			},
			sales: {
				alias: "Properties Registered", // Label for the y-axis
			},
		},
	};

	const columnsTopPropertyOwner = [
		{
			title: "User Name",
			dataIndex: "profile_name",
			key: "name",
			width: "20%",
		},
		{
			title: "Property Count",
			dataIndex: "total_properties",
			key: "total_properties",
			width: "20%",
			render: (text) => {
				return `${new Intl.NumberFormat().format(text)} Properties`; // Add "Properties" before the count
			},
		},
	];

	const columnsPropertyCountByCountry = [
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
			width: "20%",
			render: (text) => {
				const countryCode = countryCodeMap[text];
				if (countryCode) {
					return (
						<span>
							<Flag
								code={countryCode}
								style={{ width: "20px", marginRight: "8px" }}
							/>
							{text}
						</span>
					);
				}
				return text;
			},
		},
		{
			title: "Property Count",
			dataIndex: "property_count",
			key: "property_count",
			width: "20%",
			render: (text) => {
				return `${new Intl.NumberFormat().format(text)} Properties`; // Add "Properties" before the count
			},
		},
	];

	const transactionsConfig = {
		data: [
			{
				type: transactionPeriod,
				metric: "Total Revenue",
				value: transactions.totalAmount,
			},
			// {
			// 	type: transactionPeriod,
			// 	metric: "Total Property",
			// 	value: transactions.totalCount,
			// },
		],
		xField: "type",
		yField: "value",
		seriesField: "metric",
		color: ({ metric }) => (metric === "Total Revenue" ? "#4CAF50" : "#0000FF"),
		columnWidthRatio: 0.4,
		label: {
			position: "top", // Place label on top of the bar for clarity
			style: {
				fill: "#FFFFFF",
				opacity: 1,
			},
		},
		xAxis: {
			label: {
				autoHide: true,
				autoRotate: false,
			},
		},

		yAxis: {
			min: 1000,
			max:
				transactions.totalAmount > 2000
					? Math.ceil(transactions.totalAmount / 10000) * 10000
					: 2000, // Dynamic max: rounds up to nearest 10,000 for values above 2000, or uses 2000 otherwise
			tickInterval: transactions.totalAmount > 2000 ? 10000 : 250, // Adjust tick interval for larger ranges
			label: {
				formatter: (v) => `${v}`,
			},
		},
		meta: {
			type: {
				alias: "Period",
			},
			value: {
				alias: "Metrics",
			},
			metric: {
				alias: "Metric Type",
			},
		},
	};

	const handleTransactionChange = (newPeriod) => {
		console.log(newPeriod, "newPeriod");
		setTransactionPeriod(newPeriod);
		setFromDate(null); // Reset date fields when changing the period
		setToDate(null);

		if (newPeriod !== "custom") {
			console.log(newPeriod, "newPeriod1111");
			fetchTransactionData(newPeriod);
		}
	};

	const handleFromDateChange = (date, dateString) => {
		console.log(date, "startdate");
		setFromDate(dateString);
		if (dateString && toDate) {
			// Call API with correct order: fromDate first, toDate second
			fetchTransactionData("custom", dateString, toDate);
		}
	};

	const handleToDateChange = (date, dateString) => {
		console.log(date, "endate");
		setToDate(dateString);
		if (fromDate && dateString) {
			// Call API with correct order: fromDate first, toDate second
			fetchTransactionData("custom", fromDate, dateString);
		}
	};

	const handleViewTransactions = () => {
		let query = `/transactions?period=${transactionPeriod}`;
		if (transactionPeriod === "custom" && fromDate && toDate) {
			query += `&fromDate=${fromDate}&toDate=${toDate}`;
		}
		navigate(query);
	};

	// If loading, show the spinner overlay
	if (loading) {
		return (
			<div className="spinner-overlay">
				<Spin size="large" />
			</div>
		);
	}

	return (
		<div>
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "column",
					backgroundColor: "white", // Changed to camelCase
					padding: "20px",
					borderRadius: "8px", // Changed to camelCase
					boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
					marginBottom: "20px", // Keep quotes for string values
				}}
			>
				<h3 className="dashboard_title">Welcome Admin 👋🏻</h3>
				<p className="page-sub-title" style={{ color: "green" }}>
					Here’s a snapshot of your latest statistics and insights to help you
					manage the platform effectively.
				</p>
			</div>

			{/* first row */}
			<div className="d-flex flex-row gap-3 mb-3">
				<div className="card-box small-card">
					<div>
						<p className="desc">Total Users Registered</p>
						<h4 className="mb-0 sub-title">{customerData}</h4>
					</div>
					<Button
						label="	View Users"
						icon="pi pi-arrow-right"
						iconPos="right"
						style={{
							backgroundColor: "#EFF5FB",
							marginTop: "10px",
							border: "#EFF5FB",
							color: "black",
							borderRadius: "10px",
							fontSize: "13px",
							textAlign: "left",
							transition: "background-color 0.3s ease",
						}}
						onClick={() => navigate("/users")}
						onMouseOver={(e) => {
							e.target.style.backgroundColor = "#D7E8F8";
							e.target.style.color = "#black";
						}}
						onMouseOut={(e) => {
							e.target.style.backgroundColor = "#EFF5FB";
							e.target.style.color = "#black";
						}}
					></Button>
				</div>
				<div className="card-box small-card">
					<div>
						<p className="desc">Number of Contact Us Forms Received</p>
						<h4 className="mb-0 sub-title">{contactUsData}</h4>
					</div>
					<Button
						label="	View Forms"
						icon="pi pi-arrow-right"
						iconPos="right"
						style={{
							backgroundColor: "#E8F7F0",
							marginTop: "10px",
							border: "#E8F7F0",
							color: "black",
							borderRadius: "10px",
							fontSize: "13px",
							textAlign: "left",
							transition: "background-color 0.3s ease",
						}}
						onClick={() => navigate("/professionals")}
						onMouseOver={(e) => {
							e.target.style.backgroundColor = "#CDE7DB";
							e.target.style.color = "#black";
						}}
						onMouseOut={(e) => {
							e.target.style.backgroundColor = "#E8F7F0";
							e.target.style.color = "#black";
						}}
					></Button>
				</div>
				<div className="card-box small-card">
					<div>
						<p className="desc"> Number of Properties</p>
						<h4 className="mb-0 sub-title">{propertyData}</h4>
					</div>
					<Button
						label="	View Properties"
						icon="pi pi-arrow-right"
						iconPos="right"
						style={{
							backgroundColor: "#EFF5FB",
							marginTop: "10px",
							border: "#EFF5FB",
							color: "black",
							borderRadius: "10px",
							fontSize: "13px",
							textAlign: "left",
							transition: "background-color 0.3s ease",
						}}
						onClick={() => navigate("/properties")}
						onMouseOver={(e) => {
							e.target.style.backgroundColor = "#D7E8F8";
							e.target.style.color = "#black";
						}}
						onMouseOut={(e) => {
							e.target.style.backgroundColor = "#EFF5FB";
							e.target.style.color = "#black";
						}}
					></Button>
				</div>
				<div className="card-box revenue-card">
					<div className="revenue-card-content">
						<div className="icon-container">
							<i
								className="pi pi-dollar"
								style={{ fontSize: "24px", color: "#4CAF50" }}
							></i>
						</div>
						<div className="revenue-info">
							<p className="revenue-desc">Total Revenue</p>
							<h4 className="revenue-amount">${revenueData}</h4>
						</div>
					</div>
				</div>
			</div>

			{/* second row */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					marginTop: "20px",
				}}
			>
				<div style={{ width: "50%", marginRight: "20px", height: "100%" }}>
					<Card style={{ height: "250px" }}>
						<h4 className="headingTitle">Users with Most Properties</h4>
						<Table
							columns={columnsTopPropertyOwner}
							dataSource={propertyOwner}
							rowKey="course_id"
							pagination={false}
						/>
					</Card>
				</div>
				<div style={{ width: "50%", height: "100%" }}>
					<Card style={{ height: "250px" }}>
						<h4 className="headingTitle">Property Count by Country</h4>
						<Table
							columns={columnsPropertyCountByCountry}
							dataSource={propertyCountByCountryData}
							rowKey="course_id"
							pagination={false}
						/>
						{/* <div
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							
							}}
						>
							{propertyCountByCountryData.map((item) => (
								<div
									key={item.country}
									style={{
										display: "flex",
										justifyContent: "space-between",
										width: "100%",
										marginBottom: "10px",
										alignItems: "center",
									}}
								>
								
									<Flag
										code={countryCodeMap[item.country] || ""} 
										alt={item.country}
										style={{
											width: "30px",
											height: "20px",
											marginRight: "10px",
										}}
									/>
									<span style={{ fontSize: "16px", fontWeight: "600" }}>
										{item.country}
									</span>
									<span
										style={{
											fontSize: "16px",
											fontWeight: "600",
											color: "#4CAF50",
										}}
									>
										{item.property_count} Properties
									</span>
								</div>
							))}
						</div> */}
					</Card>
				</div>
			</div>

			{/* third row */}
			<div
				style={{
					display: "flex",
					justifyContent: "space-between",
					flexDirection: "row",
					marginTop: "20px",
				}}
			>
				<div style={{ width: "50%", marginRight: "20px" }}>
					<Card>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<div>
									<h5 className="mb-5 title">Number of Registered Users</h5>
								</div>
								<div style={{ marginBottom: "20px" }}>
									<Select
										defaultValue="week"
										style={{ width: 150 }}
										onChange={handlePeriodChange}
									>
										<Option value="today">Today</Option>
										<Option value="week">This Week</Option>
										<Option value="month">This Month</Option>
									</Select>
								</div>
							</div>
						</div>

						{/* Graph Section */}
						<div>
							<Column {...config} />
						</div>
					</Card>
				</div>
				<div style={{ width: "50%" }}>
					<Card>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<div>
									<h5 className="mb-5 title">Number of Active Properties</h5>
								</div>
								<div>
									<Select
										defaultValue="week"
										style={{ width: 150 }}
										onChange={handlePropertyPeriodChange}
									>
										<Option value="today">Today</Option>
										<Option value="week">This Week</Option>
										<Option value="month">This Month</Option>
									</Select>
								</div>
							</div>
						</div>

						{/* Graph Section */}
						<div>
							<Column {...propertyConfig} />
						</div>
					</Card>
				</div>
			</div>

			{/* fourth row */}
			{/* <div style={{ marginTop: "20px" }}>
				<div>
					<Card>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<div>
									<h5 className="mb-5 title">Total Revenue</h5>
								</div>
								<div style={{ marginBottom: "20px" }}>
									<Select
										defaultValue="today"
										style={{ width: 250 }}
										onChange={handleTransactionChange}
									>
										<Option value="today">Today</Option>
										<Option value="last 10 days">Last 10 days</Option>
										<Option value="last 1 month">Last 1 month</Option>
										<Option value="last 3 months">Last 3 months</Option>
										<Option value="last 6 months">Last 6 months</Option>
										<Option value="last year">Last year</Option>
										<Option value="custom">Custom Range</Option>
									</Select>

									<Button
										label="Generate Report"
										onClick={handleViewTransactions}
										severity="info"
										style={{
											marginLeft: "10px",
											borderRadius: "5px",
											height: "35px",
										}}
									/>
								</div>
							</div>

						
							{transactionPeriod === "custom" && (
								<div
									style={{
										display: "flex",
										flexDirection: "row-reverse",
										// justifyContent: "space-between",
									}}
								>
									<div style={{ marginLeft: "10px" }}>
										<DatePicker
											placeholder="From Date"
											onChange={handleFromDateChange}
											value={fromDate ? moment(fromDate, "YYYY-MM-DD") : null}
										/>
									</div>
									<div>
										<DatePicker
											placeholder="To Date"
											onChange={handleToDateChange}
											value={toDate ? moment(toDate, "YYYY-MM-DD") : null}
										/>
									</div>
								</div>
							)}

						
							<div>
								<Column {...transactionsConfig} />
							</div>
						</div>
					</Card>
				</div>
			</div> */}

			<div
				style={{
					marginTop: "20px",
				}}
			>
				<div>
					<Card>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<div
								style={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
								}}
							>
								<div>
									<h5 className="mb-5 title">Total Revenue Generated</h5>
								</div>
								<div style={{ marginBottom: "20px" }}>
									<Select
										defaultValue="today"
										style={{ width: 200 }}
										onChange={handleTransactionChange}
									>
										<Option value="today">Today</Option>
										<Option value="last 10 days">Last 10 days</Option>
										<Option value="last 1 month">Last 1 month</Option>
										<Option value="last 3 months">Last 3 months</Option>
										<Option value="last 6 months">Last 6 months</Option>
										<Option value="last year">Last year</Option>
										<Option value="custom">Custom Range</Option>
									</Select>

									<Button
										label="Generate Report"
										onClick={handleViewTransactions}
										severity="info"
										style={{
											marginLeft: "10px",
											borderRadius: "5px",
											height: "35px",
										}}
									/>

									{transactionPeriod === "custom" && (
										<div
											style={{
												display: "flex",
												flexDirection: "row-reverse",
												marginTop: "20px",
												// justifyContent: "space-between",
											}}
										>
											<div>
												<DatePicker
													placeholder="To Date"
													onChange={handleToDateChange}
													value={toDate ? moment(toDate, "YYYY-MM-DD") : null}
													disabledDate={(current) =>
														current && current > moment().endOf("day")
													}
												/>
											</div>
											<div style={{ marginRight: "10px" }}>
												<DatePicker
													placeholder="From Date"
													onChange={handleFromDateChange}
													value={
														fromDate ? moment(fromDate, "YYYY-MM-DD") : null
													}
													disabledDate={(current) =>
														current && current > moment().endOf("day")
													}
												/>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
						{/* Graph Section */}
						<div
							style={{
								marginTop: "20px",
								background: "rgba(255, 255, 255, 0.8)",
								padding: "15px",
								borderRadius: "8px",
							}}
						>
							<h2 style={{ margin: 0, color: "#333", fontWeight: 700 }}>
								${transactions.totalAmount}
							</h2>
							<p style={{ margin: 0, color: "#666", fontWeight: 500 }}>
								Total Revenue
							</p>
							<h2
								style={{ margin: "10px 0 0", color: "#333", fontWeight: 700 }}
							>
								{transactions.totalCount}
							</h2>
							<p style={{ margin: 0, color: "#666", fontWeight: 500 }}>
								Properties
							</p>
						</div>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
